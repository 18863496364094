import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-449e9cfc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "sidebar"
};
const _hoisted_2 = {
  class: "child_text"
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  class: "menu-name"
};
import { computed, watch, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { TOKEN, LOGIN_ROUTER } from '@/const/index.js';
import { getItem } from '@/utils/utils';
export default {
  setup(__props) {
    const items = [{
      icon: "icon-shouye",
      index: "/home",
      title: "首页"
    }, {
      icon: 'icon-shujudapan',
      index: '/statistics',
      title: "数据大盘"
    }, {
      icon: "icon-bozhu",
      index: "0",
      title: "博主查找",
      subs: [{
        index: "/bosSearch",
        title: "博主搜索",
        icon: ''
      }, {
        index: "/synthe",
        title: "博主排行",
        icon: ''
      }, {
        index: "/bosContrast",
        title: "博主对比",
        icon: ''
      }, {
        index: "/monitor",
        title: "博主监控",
        icon: ''
      }, {
        index: "/stope",
        title: "博主回采",
        icon: ''
      }]
    }, {
      icon: "icon-guance",
      index: "1",
      title: "博文观测",
      subs: [{
        index: "/blogSearch",
        title: "博文搜索",
        icon: ''
      }, {
        index: "/blogHotRanking",
        title: "博文排行",
        icon: ''
      }, {
        index: "/blogMonitor",
        title: "博文监测",
        icon: ''
      }, {
        index: "/blogReport",
        title: "博文监测报告",
        icon: ''
      }, {
        index: "/wordCloud",
        title: "词云生成",
        icon: ''
      }, {
        index: '/finalReport',
        title: '投放报告',
        icon: ''
      }, {
        index: '/propagationAnalysis',
        title: '传播分析',
        icon: 'icon-NEW-copy'
      }]
    }, {
      icon: "icon-yuqing",
      index: "2",
      title: "话题观测",
      subs: [{
        index: "/topicMonitor",
        title: "话题监测",
        icon: ''
      }, {
        index: "/topicReport",
        title: "话题监测报告",
        icon: ''
      }, {
        index: "/topicheatedRanking",
        title: "话题排行",
        icon: ''
      }, {
        index: "/topicAnalysis",
        title: "话题分析",
        icon: ''
      }]
    }, {
      icon: "icon-resou1",
      index: "3",
      title: "热搜查询",
      subs: [{
        index: "/topictopRanking",
        title: "热搜榜单",
        icon: ''
      }, {
        index: "/hotSearch",
        title: "热搜报告",
        icon: ''
      }, {
        index: "/hotHistory",
        title: "历史热搜搜索",
        icon: ''
      }]
    }, {
      icon: "icon-pinpai",
      index: "4",
      title: "品牌分析",
      subs: [{
        index: "/brandSearch",
        title: "品牌搜索",
        icon: ''
      }, {
        index: "/brandRanking",
        title: "品牌排行",
        icon: ''
      }, {
        index: "/brandContrast",
        title: "品牌对比",
        icon: ''
      }, {
        index: "/brandReport",
        title: "品牌投放报告",
        icon: ''
      }, {
        index: "/brandAudience",
        title: "品牌受众分析",
        icon: ''
      }]
    }, {
      icon: "icon-gongjuxiang",
      index: "5",
      title: "工具箱",
      subs: [{
        index: "/bosContrast",
        title: "博主对比",
        icon: ''
      }, {
        index: "/monitor",
        title: "博主监控",
        icon: ''
      }, {
        index: '/blogMonitor',
        title: '博文监测',
        icon: ''
      }, {
        index: '/wordCloud',
        title: '词云生成',
        icon: ''
      }, {
        index: '/topicMonitor',
        title: '话题监测',
        icon: ''
      }, {
        index: '/brandContrast',
        title: '品牌对比',
        icon: ''
      }, {
        index: '/finalReport',
        title: '投放报告',
        icon: ''
      }]
    }, {
      icon: "icon-biaoge",
      index: "/dataExport",
      title: "数据导出"
    }, {
      icon: "icon-geren",
      index: "6",
      title: "个人中心",
      subs: [{
        index: "/user",
        title: "账户信息",
        icon: ''
      }, {
        index: "/orderInfo",
        title: "订单中心",
        icon: ''
      }, {
        index: "/shares",
        title: "有偿拉新",
        icon: ''
      }, {
        index: "/withdraw",
        title: "提现记录",
        icon: ''
      }]
    }, {
      icon: "icon-shoucang-1",
      index: "/bosCollect",
      title: "我的收藏"
    }, {
      icon: "icon-xianxing",
      index: "/member",
      title: "充值续费"
    }, {
      icon: "icon-dingwei1",
      index: "/life",
      title: "本地生活"
    }, {
      icon: "icon-jiance",
      index: "/lifeCustom",
      title: "数据定制"
    }];
    const route = useRoute(),
      router = useRouter();
    const showLogin = ref(null);
    const onRoutes = computed(() => {
      if (LOGIN_ROUTER.includes(route.path)) return false;
      if (route.meta.activeMenu) return route.meta.activeMenu;
      return route.path;
    });
    const onChange = e => {
      if (!getItem(TOKEN)) {
        if (LOGIN_ROUTER.includes(e)) {
          showLogin.value.openLogin(true);
        }
      }
    };
    const store = useStore();
    const collapse = computed(() => store.state.collapse);
    return (_ctx, _cache) => {
      const _component_el_menu_item = _resolveComponent("el-menu-item");
      const _component_el_sub_menu = _resolveComponent("el-sub-menu");
      const _component_el_menu = _resolveComponent("el-menu");
      const _component_vLogin = _resolveComponent("vLogin");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_menu, {
        class: "sidebar-el-menu menuBox",
        "default-active": _unref(onRoutes),
        collapse: _unref(collapse),
        onSelect: onChange,
        "background-color": "#FFFFFF",
        "text-color": "#1D2129",
        "active-text-color": "#FF8B12",
        "unique-opened": "",
        router: ""
      }, {
        default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(items, item => {
          return _openBlock(), _createElementBlock(_Fragment, null, [item.subs ? (_openBlock(), _createBlock(_component_el_sub_menu, {
            index: item.index,
            key: item.index,
            class: _normalizeClass(["selectBox", {
              'active-top': (item.title == '品牌分析' || item.title == '个人中心' || item.title == '博主查找') && !_unref(collapse),
              'active-width': !_unref(collapse)
            }])
          }, {
            title: _withCtx(() => [_createElementVNode("i", {
              class: _normalizeClass(['iconfont', item.icon])
            }, null, 2), _createElementVNode("span", null, _toDisplayString(item.title), 1)]),
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.subs, subItem => {
              return _openBlock(), _createElementBlock(_Fragment, null, [subItem.subs ? (_openBlock(), _createBlock(_component_el_sub_menu, {
                index: subItem.index,
                key: subItem.index
              }, {
                title: _withCtx(() => [_createTextVNode(_toDisplayString(subItem.title), 1)]),
                default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subItem.subs, (threeItem, i) => {
                  return _openBlock(), _createBlock(_component_el_menu_item, {
                    key: i,
                    index: threeItem.index
                  }, {
                    default: _withCtx(() => [_createTextVNode(_toDisplayString(threeItem.title), 1)]),
                    _: 2
                  }, 1032, ["index"]);
                }), 128))]),
                _: 2
              }, 1032, ["index"])) : (_openBlock(), _createBlock(_component_el_menu_item, {
                index: subItem.index,
                key: subItem.index
              }, {
                default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", null, _toDisplayString(subItem.title), 1), subItem.icon ? (_openBlock(), _createElementBlock("span", _hoisted_3, [_createElementVNode("i", {
                  style: {
                    "color": "red !important"
                  },
                  class: _normalizeClass(['iconfont', subItem.icon])
                }, null, 2)])) : _createCommentVNode("", true)])]),
                _: 2
              }, 1032, ["index"]))], 64);
            }), 256))]),
            _: 2
          }, 1032, ["index", "class"])) : (_openBlock(), _createBlock(_component_el_menu_item, {
            index: item.index,
            key: item.index,
            class: "selectBox2",
            style: _normalizeStyle(!_unref(collapse) ? 'margin: 0 20px;' : 'background: transparent !important;')
          }, {
            default: _withCtx(() => [_createElementVNode("i", {
              class: _normalizeClass(['iconfont', item.icon])
            }, null, 2), _createElementVNode("p", _hoisted_4, _toDisplayString(item.title), 1)]),
            _: 2
          }, 1032, ["index", "style"]))], 64);
        }), 64))]),
        _: 1
      }, 8, ["default-active", "collapse"]), _createVNode(_component_vLogin, {
        ref_key: "showLogin",
        ref: showLogin
      }, null, 512)]);
    };
  }
};