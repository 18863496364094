import { createVNode as _createVNode, unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-54d3e950"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content"
};
import { computed, ref, toRaw } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import vHeader from "@/components/common/Header.vue";
import vSidebar from "@/components/common/Sidebar.vue";
export default {
  setup(__props) {
    const store = useStore();
    const router = useRouter();
    const collapse = computed(() => store.state.collapse);
    // 获取滚动距离

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(vHeader), _createVNode(vSidebar), _createElementVNode("div", {
        class: _normalizeClass(["content-box", {
          'content-collapse': _unref(collapse)
        }])
      }, [_createElementVNode("div", _hoisted_1, [(_openBlock(), _createBlock(_component_router_view, {
        key: _ctx.$route.path + _ctx.$route.query.t
      }, {
        default: _withCtx(({
          Component
        }) => [_createVNode(_Transition, {
          name: "move",
          mode: "out-in"
        }, {
          default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))]),
          _: 2
        }, 1024)]),
        _: 1
      }))])], 2)]);
    };
  }
};